import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/global/seo"

// const Bold = ({ children }) => <b>{children}</b>
// const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>,
  },
  renderNode: {
    "embedded-asset-block": node => {
      const image = getImage(node.data.target)
      const alt = node.data.target.title
      if (!image) {
        // asset is not an image
        return null
      }
      return <GatsbyImage className="" image={image} alt={alt} />
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    // [BLOCKS.EMBEDDED_ASSET]: node => {
    //   return (
    //     <>
    //       <h2>Embedded Asset</h2>
    //       <pre>
    //         <code>{JSON.stringify(node, null, 2)}</code>
    //       </pre>
    //     </>
    //   )
    // },
  },
}

const LicenseInternationalDriverPage = ({ data }) => {
  const { instruction } = data.contentfulService
  return (
    <Layout>
      <Seo
        title={data.contentfulService.seo.title}
        description={data.contentfulService.seo.description.description}
      />
      <main className="service max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24 prose prose-lg">
        <h1>{data.contentfulService.title}</h1>
        <div>{instruction && renderRichText(instruction, options)}</div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query LicenseInternationalDriverPageQuery {
    contentfulService(serviceId: { eq: 101 }) {
      id
      title
      instruction {
        raw
        references {
          ... on ContentfulAsset {
            # You'll need to query contentful_id in each reference
            contentful_id
            __typename
            title
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              layout: CONSTRAINED
              # resizingBehavior: NO_CHANGE
            )
          }
        }
      }
      seo {
        title
        description {
          description
        }
        slug
      }
    }
  }
`

export default LicenseInternationalDriverPage
